import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Button from '../components/Button'
import CallFurthur from '../components/CallFurthur'
import CustomTabs from '../components/CustomTabs'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import FormCard from '../components/FormCard'
import Getquote from '../components/Getquote'
import Layout from '../components/Layout'
import Location from '../components/Location'
import QuoteForm from '../components/QuoteForm'
// import SearchPostCard from '../components/SearchPostCard'
import SEO from '../components/seo'

const TabContent = ({
  content
}) => (
    <div dangerouslySetInnerHTML={{ __html: content }} />

)

const ServiceTemplate = ({ data }) => {
  const { seo, Services: page } = data.wpPage
  const {
    title: bannerTitle,
    image,
    overview,
    benefits,
    price,
    tabEnabled,
    heroBottom,
    childService,
    sideBySide,
    contactForm,
    sectionBackground,
    serviceBoxes
  } = page
  //console.log(childService);
  return (
    <Layout>
      <SEO title={seo.title} description={seo.metaDesc} />
      <Hero
        type="primary"
        image={image?.localFile.childImageSharp.fluid}
      />
      {
        tabEnabled && <Container className="service-container" fluid={true}>
          <Row className="services__row-bg">
            <Col xl={8} className="p-0">
              <section className="service tabbedContent">
                <CustomTabs
                  tabOneContent={
                    <TabContent
                      content={overview}
                    />
                  }
                  tabTwoContent={
                    <TabContent
                      content={benefits}
                    />
                  }
                  tabThreeContent={<TabContent content={price} />}
                  tabOneLabel="OVERVIEW"
                  tabTwoLabel="BENEFITS"
                  tabThreeLabel="PRICE"
                />
              </section>
            </Col>
            <Col className="service__col-right" xl={4}>
              <Getquote
                title="Get a quote today!"
                buttonLabel="Get a free estimate"
                cssClass="margin-top-60"
              />
            </Col>
          </Row>
        </Container>
      }
      {
           heroBottom.enabled && (<Container className="service-container" fluid>
                  <Row className="services__custom_row">
                    <Col className="services__col_custom">
                      <h1 className="services__heading_custom">{heroBottom?.heading}</h1>
                      <div className="services__text_custom" dangerouslySetInnerHTML={{ __html: heroBottom?.description }} />
                    </Col>
                  </Row>
                </Container>)
      }
      {
          childService &&    <Container className="service-container" fluid>
                <Row className="services__custom_row">
                  {
                    childService?.map((item, index) => {
                      return(
                        <Col key={index} className="services__col_custom" xl={6}> 
                          <div className="services__topboxcontainer">
                            <div className="services__topboxes">
                              <div className="services__topboxesimage">
                              <GatsbyImage 
                                loading="eager"
                                image={getImage(item?.image?.localFile)}
                                alt={item?.image?.altText}
                              />
                              </div>
                              <h3>
                                {item?.title}
                              </h3>
                            </div>
                            <div className="services__topboxContent">
                              <p>{item?.description}</p>
                            </div>
                            <Link to={item?.readMoreLink}>
                              <Button type="primary" label="READ MORE" />
                            </Link>
                          </div>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Container>
      }

      {    sideBySide &&   <Container className="service-container" fluid>
                {
                  sideBySide?.map((item, index) =>{
                    return(
                      <Row key={index} className="services__custom_row services__sidebyside_row">
                        <Col className="services__sub-col_custom" xl={6}>
                            <GatsbyImage 
                                loading="eager"
                                className="services__sidebysideImage"
                                image={getImage(item?.image?.localFile)}
                                alt={item?.image?.altText}
                            />
                        </Col>
                        <Col className="services__sub-col_custom" xl={6}>
                          <h2 className="services__subheading_custom">{item?.title}</h2>
                          <div className="services__subtext_custom" dangerouslySetInnerHTML={{ __html: item?.content }} />
                          <div className="services__btn_container">
                            <Link to={item?.buttonLink}>
                              <Button type="primary" label={item?.buttonText} />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    )
                  })
                }
              </Container>
      }
      {
            contactForm.enabled && <Container className="estimate-forms">
                <Row>
                  <Col xs={{ span: 10, offset: 1 }}>
                    <FormCard Form={QuoteForm} data={contactForm} />
                  </Col>
                </Row>
              </Container>
      }
      {
            sectionBackground.enabled &&  <BackgroundImage fluid={sectionBackground?.backgroundImage?.localFile?.childImageSharp?.fluid}>
                <Container className="h-100">
                  <Row className="services__bgsection m-auto text-center" dangerouslySetInnerHTML={{ __html: sectionBackground?.content }} />
                </Container>
              </BackgroundImage>
      }
      {
            serviceBoxes &&  <Container fluid>
                <Row className="services__custom_row">
                  {
                    serviceBoxes?.map((item, index) =>{
                      return(
                        <Col key={index} xl={4} className="services__col_custom">
                          <div className="services__boxes">
                            <h3>{item?.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                          </div>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Container>
      }
      <CallFurthur />
      <Location />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query getServiceAndDesktop($id: String) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      Services {
        title
        image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        heroBottom {
          enabled
          heading
          subHeading
          description
        }
        tabEnabled
        overview
        benefits
        price
        childService {
          title
          description
          readMoreLink
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        sideBySide {
          title
          content
          buttonText
          buttonLink
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        sectionBackground {
          enabled
          content
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        serviceBoxes {
          title
          description
        }
        contactForm {
          enabled
          phone
          fax
          address
          email
        }
      }
    }
  }
`

export default ServiceTemplate
